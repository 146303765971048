/*
 * <<
 * Davinci
 * ==
 * Copyright (C) 2016 - 2017 EDP
 * ==
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * >>
 */

import React from 'react';
import { decodeMetricName, getTextWidth, getFormattedValue, getFieldAlias } from '../util';
import Comparision from '../../Comparision';
import moment from 'moment';
import { Tooltip } from 'antd';
import { handleAnalysis } from '../util';
import mathUtil from '../../../utils/mathUtil';
import { getCartesianChartMetrics } from '../../BIChart/render/chart/util';

export class Scorecard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      FilterDate: [],
    };
  }
  componentDidMount() {
    this.getFilterDate();
  }

  getFilterDate = () => {
    let FilterDate = [];
    const { requestParams } = this.props;
    if (requestParams && requestParams.filters) {
      let startDateIndex = requestParams.filters.findIndex((i) => i.operator === '>=' && (i.sqlType === 'DATE' || i.sqlType === 'DATETIME'));
      let endDateIndex = requestParams.filters.findIndex((i) => i.operator === '<=' && (i.sqlType === 'DATE' || i.sqlType === 'DATETIME'));
      if (startDateIndex > -1 && endDateIndex > -1) {
        FilterDate = [requestParams.filters[startDateIndex].value.replace(/,/g, ''), requestParams.filters[endDateIndex].value.replace(/,/g, '')];
      }
    }
    this.setState({
      FilterDate,
    });
  };

  getMetricText = (metric, visible) => {
    if (!metric || !visible) {
      return '';
    }
    const { data, queryVariables } = this.props;
    const { name, agg, format, field, fieldDisplay, randomName, workViewParamId } = metric;
    const isYoyOrMom = ['yoy', 'mom'].includes(agg);
    let Date = [];
    let CompareDate = [];
    let tip = '';
    const expression = decodeMetricName(name);
    const metricName = randomName || `${agg}(${expression})`;
    const text = data.length ? getFormattedValue(data[0][metricName], format) : '';
    const { FilterDate } = this.state;
    if (isYoyOrMom) {
      const { yoyMom } = metric;
      Date = this.getDateArr(agg, yoyMom, FilterDate).Date;
      CompareDate = this.getDateArr(agg, yoyMom, FilterDate).CompareDate;
      tip = this.getTipText(text, Date, CompareDate);
    }
    const displayName = getCartesianChartMetrics([metric])[0].displayName || '';
    const title = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || expression;
    return { text, title, tip, workViewParamId };
  };

  getRatioValue = (metric, visible) => {
    // if (!metric  || !visible) { return '' }
    if (!metric) {
      return { value: '', title: '' };
    }
    const { data, queryVariables } = this.props;
    const { name, agg, randomName, field, fieldDisplay } = metric;
    const isYoyOrMom = ['yoy', 'mom'].includes(agg);
    let Date = [];
    let CompareDate = [];
    let tip = '';
    const expression = decodeMetricName(name);
    const metricName = randomName || `${agg}(${expression})`;
    const value = data.length ? data[0][metricName] : '';
    const { FilterDate } = this.state;
    if (isYoyOrMom) {
      const { yoyMom } = metric;
      Date = this.getDateArr(agg, yoyMom, FilterDate).Date;
      CompareDate = this.getDateArr(agg, yoyMom, FilterDate).CompareDate;
      tip = this.getTipText(value, Date, CompareDate);
    }
    const displayName = getCartesianChartMetrics([metric])[0].displayName || '';
    const title = getFieldAlias(field, queryVariables || {}) || displayName || fieldDisplay || expression;
    return { value, title, tip };
  };

  getDateArr(agg, yoyMom, filter) {
    yoyMom.lastDate && (yoyMom.date = mathUtil.keyToDate(yoyMom.lastDate));
    let Date = yoyMom.date.split(',');
    let CompareDate = [];
    if (filter.length) {
      Date = filter;
    }
    let number = 1;
    let param = 'days';
    if (agg === 'yoy') {
      switch (yoyMom.period) {
        case 'week':
          number = 7;
          break;
        case 'month':
          param = 'months';
          break;
        case 'year':
          param = 'years';
          break;
      }
    } else {
      const diff = moment(Date[1]).diff(moment(Date[0]), 'day');
      number = diff + 1;
    }
    CompareDate[0] = moment(Date[0])
      .subtract(number, param)
      .format('YYYY-MM-DD');
    CompareDate[1] = moment(Date[1])
      .subtract(number, param)
      .format('YYYY-MM-DD');
    return { Date, CompareDate };
  }

  getTipText(value, Date, CompareDate) {
    let valueText = '';
    let isPercent = false;
    if (typeof value === 'string' && value.indexOf('%') > -1) {
      value = value.replace('%', '');
      isPercent = true;
    }
    let tipNumber = value === '' ? 0 : value;
    if (tipNumber > 0) {
      valueText = `上升${isPercent ? `${tipNumber}%` : `${parseFloat(tipNumber * 100).toFixed(2)}%`}`;
    } else if (tipNumber < 0) {
      valueText = `下降${isPercent ? `${tipNumber}%` : `${parseFloat(tipNumber * 100).toFixed(2)}%`}`;
    } else {
      valueText = '没有变化';
    }
    return `${Date[0].replaceAll("'", '')}至${Date[1].replaceAll("'", '')}这段时间对比${CompareDate[0]}至${CompareDate[1]}${valueText.replaceAll('-', '')}`;
  }

  renderMetric = (
    text,
    title,
    fontFamily,
    fontSize,
    color,
    prefix,
    prefixFontFamily,
    prefixFontSize,
    prefixColor,
    suffix,
    suffixFontFamily,
    suffixFontSize,
    suffixColor,
    tip,
    isMain,
    workViewParamId
  ) => {
    const style = {
      fontFamily,
      fontSize,
      color,
    };
    const stylePrefix = {
      fontFamily: prefixFontFamily,
      fontSize: prefixFontSize,
      color: prefixColor,
    };
    const styleSuffix = {
      fontFamily: suffixFontFamily,
      fontSize: suffixFontSize,
      color: suffixColor,
    };

    const { showAnalysis } = this.props;

    return (
      <Tooltip placement="bottomLeft" title={tip} mouseEnterDelay={0.5}>
        <p className="summaryTitle">
          {(prefix || title) && (
            <span className="summaryPrefix" style={stylePrefix}>
              {prefix || title}&nbsp;&nbsp;
            </span>
          )}
          <span
            className="summaryText"
            style={style}
            onClick={() => {
              console.log(isMain);
              console.log(showAnalysis);
              console.log(workViewParamId);
              if (isMain && showAnalysis && workViewParamId) {
                handleAnalysis(workViewParamId);
              }
            }}>
            {text}
          </span>
          {suffix && (
            <span className="summarySuffix" style={styleSuffix}>
              &nbsp;&nbsp;{suffix}
            </span>
          )}
        </p>
      </Tooltip>
    );
  };

  computeFontSize = (prefixHeader, headerText, suffixHeader, prefixContent, contentText, suffixContent, prefixFooter, footerText, suffixFooter) => {
    const hasHeader = prefixHeader || headerText || suffixHeader;
    const hasContent = prefixContent || contentText || suffixContent;
    const hasFooter = prefixFooter || footerText || suffixFooter;

    const { width, height } = this.props;
    const maxPartSize = 16;

    const exactWidth = width * (width <= 150 ? 1 : width <= 250 ? 0.9 : 0.7) - 16 * 2;
    const sumPartsW = Math.max(
      getTextWidth(prefixHeader + headerText + suffixHeader, '', '12px'),
      getTextWidth(prefixContent + suffixContent, '', '12px') + getTextWidth(contentText, '', '32px'),
      getTextWidth(prefixFooter + footerText + suffixFooter, '', '12px')
    );

    const exactHeight = height * (height <= 150 ? 1 : height <= 250 ? 0.9 : 0.7) - 40;
    const sumPartsH = (hasHeader ? 3 : 0) + (hasContent ? 8 : 0) + (hasFooter ? 3 : 0);
    const gapH = 8;
    const sumGapH = (hasHeader ? gapH : 0) + (hasContent ? gapH : 0) + (hasFooter ? gapH : 0);

    const exactPartSize = Math.min((exactWidth / sumPartsW) * 3, (exactHeight - sumGapH) / sumPartsH, maxPartSize);
    return {
      titleFontSize: Math.floor(3 * exactPartSize),
      contentFontSize: Math.floor(8 * exactPartSize),
    };
  };

  render() {
    const { metrics, chartStyles, data } = this.props;
    let metricHeader;
    let metricContent;
    let metricFooter;
    let metricRatio;
    if (metrics.length === 1) {
      metricContent = metrics[0];
    } else {
      [metricContent, metricRatio, metricHeader, metricFooter] = metrics;
    }
    const { summary, grid } = chartStyles;
    const {
      headerVisible,
      headerFontFamily,
      headerColor,
      prefixHeader,
      prefixHeaderFontFamily,
      prefixHeaderColor,
      suffixHeader,
      suffixHeaderFontFamily,
      suffixHeaderColor,

      contentVisible,
      contentFontFamily,
      contentColor,
      prefixContent,
      prefixContentFontFamily,
      prefixContentColor,
      suffixContent,
      suffixContentFontFamily,
      suffixContentColor,

      footerVisible,
      footerFontFamily,
      footerColor,
      prefixFooter,
      prefixFooterFontFamily,
      prefixFooterColor,
      suffixFooter,
      suffixFooterFontFamily,
      suffixFooterColor,

      fontSizeFixed,
      fontSizeMain,
      fontSizeSub,
    } = summary;

    const backgroundColor = grid ? grid.backgroundColor : 'transparent';

    const headerText = this.getMetricText(metricHeader, headerVisible);
    const contentText = this.getMetricText(metricContent, contentVisible);
    const footerText = this.getMetricText(metricFooter, footerVisible);
    const { value: ratio, title: ratioTitle, tip } = this.getRatioValue(metricRatio);

    let titleFontSize = '12px';
    let contentFontSize = '28px';
    if (fontSizeFixed) {
      titleFontSize = `${fontSizeSub}px`;
      contentFontSize = `${fontSizeMain}px`;
    }

    return (
      <div className="summary" style={{ backgroundColor }}>
        {this.renderMetric(
          headerText.text,
          headerText.title,
          headerFontFamily,
          titleFontSize,
          headerColor,
          prefixHeader,
          prefixHeaderFontFamily,
          titleFontSize,
          prefixHeaderColor,
          suffixHeader,
          suffixHeaderFontFamily,
          titleFontSize,
          suffixHeaderColor,
          headerText.tip
        )}
        <div className="summaryMain">
          {this.renderMetric(
            contentText.text,
            '',
            contentFontFamily,
            contentFontSize,
            contentColor,
            prefixContent,
            prefixContentFontFamily,
            titleFontSize,
            prefixContentColor,
            suffixContent,
            suffixContentFontFamily,
            titleFontSize,
            suffixContentColor,
            contentText.tip,
            true,
            contentText.workViewParamId
          )}
          {ratio !== '' && <Comparision notShowToolTips={true} number={ratio} title={ratioTitle} tip={tip} />}
        </div>
        {this.renderMetric(
          footerText.text,
          footerText.title,
          footerFontFamily,
          titleFontSize,
          footerColor,
          prefixFooter,
          prefixFooterFontFamily,
          titleFontSize,
          prefixFooterColor,
          suffixFooter,
          suffixFooterFontFamily,
          titleFontSize,
          suffixFooterColor,
          footerText.tip
        )}
      </div>
    );
  }
}

export default Scorecard;
